import React, { Children, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import {
  Autocomplete,
  Typography,
  Stack,
  IconButton,
  Button,
  Paper,
  Popper,
  useTheme,
  LinearProgress,
  Backdrop,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { debounce } from '@mui/material/utils'
import NanoMenuItem from './NanoMenuItem'
import { ArrowDownIcon, PoiIcon, SiloIcon, AddIcon, ContentIcon, LinkIcon } from '../icons'
import NanoAvatar from './NanoAvatar'
import NanoChip from './NanoChip'

const propTypes = {
  /** text displayed above input */
  label: PropTypes.string,
  /** text displayed above input */
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  /**
   * Function called to get all options, can handle the search text from the input
   * @param {string} search
   * @returns {Promise<any[]>} options
   */
  fetchOptions: PropTypes.func.isRequired,
  /** Function triggered on changement of selection. */
  onChange: PropTypes.func,
  /** Function triggered on changement of selection. */
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        section: PropTypes.string,
      })
    ),
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      section: PropTypes.string,
    }),
  ]),
  onInputChange: PropTypes.func,
  /** TextFieldProps
   * - [TextField API](https://mui.com/material-ui/api/text-field/)
   */
  multiple: PropTypes.bool,
  /** @type {PropTypes.Requireable<import('@mui/material').TextFieldProps>} */
  textFieldProps: PropTypes.shape({}),
  popperProps: PropTypes.shape({}),
}

const defaultProps = {
  label: '',
  error: null,
  fetchOptions: (search = null) => [],
  onChange: null,
  value: [],
  required: false,
  multiple: true,
  textFieldProps: {},
  popperProps: {},
  onInputChange: () => null,
}

/**
 * @param {(PropTypes.InferProps<NanoAutocomplete.propTypes> & Partial<import('@mui/material').AutocompleteProps>)} props
 */
/**
 * @type {import('react').ForwardRefExoticComponent<PropTypes.InferProps<NanoAutocomplete.propTypes> & Partial<import('@mui/material').AutocompleteProps>>}
 */
const NanoAutocomplete = React.forwardRef(
  (
    {
      label,
      fetchOptions,
      onChange,
      error,
      value,
      required,
      inputValue,
      onInputChange,
      textFieldProps,
      multiple,
      popperProps,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [options, setOptions] = useState([])
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const fetchData = (v) => {
      setLoading(true)
      fetchOptions(v)
        .then((response) => {
          setOptions(response)
        })
        .finally(() => setLoading(false))
    }

    const handleChange = (event, v) => {
      onInputChange(event, v)
      if (typeof fetchOptions === 'function') fetchDataDebounced(v)
    }
    const fetchDataDebounced = useMemo(
      () => debounce(fetchData, 500),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    // @FIXME: définir une prop pour cette partie
    const sectionData = (option) => ({
      user: {
        icon: (
          <NanoAvatar sx={{ width: 40, height: 40 }}>
            {option.label
              .split(' ')
              .map((s) => s[0])
              .join('')
              .toUpperCase()}
          </NanoAvatar>
        ),
        primaryText: option.label,
        secondaryText: `${option?.email}`,
      },
      bin: {
        icon: (
          <NanoAvatar sx={{ width: 40, height: 40, backgroundColor: 'greyBackground.main' }}>
            {option.is_combined ? (
              <>
                <SiloIcon color={theme.palette.text.secondary} />
                <LinkIcon height="18" width="18" />
              </>
            ) : (
              <SiloIcon color={theme.palette.text.secondary} />
            )}
          </NanoAvatar>
        ), // @TODO : Create a new SiloIcon for combined bins
        primaryText: option.label,
        secondaryText: `${option.device_content ?? ''}`,
      },
      group: {
        icon: (
          <NanoAvatar sx={{ width: 40, height: 40, backgroundColor: 'greyBackground.main' }}>
            <PoiIcon fill={theme.palette.text.secondary} />
          </NanoAvatar>
        ),
        primaryText: option.label,
        secondaryText: `${t('binWithCount2', { count: option.devices?.filter((device) => !device.is_combined).length })} ${option.devices?.filter((device) => device.is_combined).length ? ` (${t('combinedWithCount', { count: option.devices?.filter((device) => device.is_combined).length })})` : ''}`,
      },
      level_filter: {
        icon: null,
        primaryText: option.label,
      },
      status: {
        icon: null,
        primaryText: option.label,
      },
      device_content: {
        icon: (
          <NanoAvatar sx={{ width: 40, height: 40, backgroundColor: 'greyBackground.main' }}>
            <ContentIcon width="24" height="24" />
          </NanoAvatar>
        ),
        primaryText: option.label,
      },
    })

    const customPaper = ({ children, props }) => (
      <Paper {...props}>
        {children}
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ position: 'sticky', bottom: 5, backgroundColor: 'transparent' }}
        >
          <Button
            variant="text"
            sx={{ height: 52 }}
            onClick={() => {
              setOpen(false)
            }}
          >
            {t('finished')}
          </Button>
        </Stack>
      </Paper>
    )

    const customPopper = (props) => (
      // Use a Backdrop wich is a shortcut that add a div over the whole viewport.
      // Should not interfere with native Popper behaviour because the open state is synchrionised
      // Fix the case when an autocompelte is used inside a dialog, the backdrop here stop the propagation of the click event to backdrop inside the dialog. Resulting in preventing the closing of the dialog at the same time of the popper
      <Backdrop open={props.open} invisible>
        <Popper
          {...props}
          popperOptions={{
            placement: 'bottom',
          }}
          {...popperProps}
        />
      </Backdrop>
    )
    return (
      <Autocomplete
        // props autocomplete
        ref={ref}
        openOnFocus
        multiple={multiple}
        open={open}
        filterSelectedOptions
        onChange={onChange}
        popupIcon={<ArrowDownIcon />}
        isOptionEqualToValue={(option, v) => option.id === v.id}
        disableCloseOnSelect={multiple}
        options={options}
        loading={loading}
        loadingText={
          <Typography sx={{ pt: 3 }} align="center">
            {t('loading')}
            <LinearProgress color="secondary" />
          </Typography>
        }
        inputValue={inputValue}
        value={value ?? (multiple ? [] : null)} // FORCE conversion to array in case of null (fix in parent component) -  NEED TO BE HERE AND NOT AUTOCOMPLETE to becompatible with React Hook Form
        onOpen={() => {
          setOpen(true)
          if (typeof fetchOptions === 'function') fetchDataDebounced()
        }}
        onClose={() => {
          setOpen(false)
        }}
        onInputChange={handleChange}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            error={!!error}
            helperText={error?.message}
            label={label}
            required={required}
            InputProps={{
              ...params.InputProps,
            }}
            {...textFieldProps}
          />
        )}
        renderTags={(_, getTagProps) =>
          _.map((option, index) => (
            <NanoChip
              key={index}
              section={option.section}
              label={option.label}
              tooltip={`${option.label} ${option.farm_name ? `- ${option.farm_name}` : ''} `}
              tagOption={option} // @TODO: very specific for user case, maybe we should move it elsewhere
              {...getTagProps({ index })}
            />
          ))
        }
        // Render options,render group and props linked
        groupBy={(option) => option.section}
        renderOption={(props, option) => {
          const { icon, primaryText, secondaryText } = sectionData(option)[option.section] || {}
          return (
            <NanoMenuItem
              icon={icon}
              listItemTextProps={{
                primary: primaryText,
                secondary: secondaryText,
                primaryTypographyProps: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mr: 2,
                },
                secondaryTypographyProps: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mr: 2,
                },
              }}
              listItemProps={{ sx: { ml: 0, mr: 2 } }}
              {...props}
            >
              <Stack direction="row" alignItems="center" alignContent="flex-start">
                <IconButton sx={{ backgroundColor: 'secondary.main' }}>
                  <AddIcon stroke="white" />
                </IconButton>
              </Stack>
            </NanoMenuItem>
          )
        }}
        renderGroup={(params) => {
          return (
            <Stack sx={{ m: 2 }}>
              <Typography variant="body1" color="text.secondary" pb={1}>
                {/* with the Count we force the plural version if it exists for the key */}
                {t(params.group, { count: Children.count(params.children) })} (
                {Children.count(params.children)})
              </Typography>
              {params.children}
            </Stack>
          )
        }}
        // popup Style
        PaperComponent={multiple && customPaper}
        PopperComponent={customPopper}
        {...props}
      />
    )
  }
)

NanoAutocomplete.propTypes = propTypes
NanoAutocomplete.defaultProps = defaultProps

export default NanoAutocomplete
