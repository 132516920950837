import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TopPage from '../layout/TopPage'
import { useTheme, useMediaQuery, Button, Stack, Typography } from '@mui/material'
import ButtonWhite from '../../shared/components/ButtonWhite'
import NanoPaper from '../../shared/components/NanoPaper'
import { IdeaIcon, SupportIcon } from '../../shared/icons/index'
import ConfirmationModal from '../device/components/ConfirmationModal'
import FeedbackModal from './FeedbackModal'
import { useSessionStore, useWorkspaceSettingsStore } from '../../shared/store'
import { ToastContext } from '../../shared/contexts'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import { supportedSupportLanguages } from '../../shared/utils/langUtils'

const propTypes = {}

const defaultProps = {}

function HelpPage() {
  const { t } = useTranslation()
  const theme = useTheme()
  const state = useWorkspaceSettingsStore()
  const email = state.getSetting('support_email')
  const phone = state.getSetting('support_phone')
  const webhookDoc = state.getSetting('webhook_doc')
  const apiDoc = state.getSetting('api_doc')
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const [openDialog, setOpenDialog] = useState(false)
  const [successPage, setSuccessPage] = useState(false)
  const toastContext = useContext(ToastContext)
  const userLanguage = useSessionStore(
    (state) => state.user.configuration.preferred_language?.split('-')[0]
  )

  const onSuccess = (ok) => {
    ok ? setSuccessPage(true) : toastContext.sendMessage(t('api_common_error'), 'error')
  }

  const supportedLanguage =
    supportedSupportLanguages.find((lang) => lang.split('-')[0] === userLanguage) ?? 'en'

  return (
    <>
      <TopPage back justifyContent="center" title={mobileFormat ? null : t('help_menu')} />

      <Stack
        sx={{ m: 'auto', mt: 0, width: mobileFormat ? '90%' : '50%' }}
        direction="column"
        display="flex"
        spacing={3}
      >
        <NanoPaper>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1.5}
            >
              <Typography variant="h5">{t('email_support')}</Typography>
              <Button
                sx={{ p: 0 }}
                variant="text"
                href={`mailto:${email?.value}?subject=Binconnect V2 - Need support`}
              >
                {email?.value}
              </Button>
            </Stack>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1.5}
            >
              <Typography variant="h5">{t('phone_support')}</Typography>
              <Typography variant="body1">{phone?.value}</Typography>
            </Stack>
          </Stack>
        </NanoPaper>
        <NanoPaper>
          <Stack
            direction={mobileFormat ? 'column' : 'row'}
            alignItems="center"
            justifyContent="space-between"
            spacing={2.5}
          >
            <Typography variant="body1">{t('ideas_text')}</Typography>
            <Stack flexShrink={0}>
              <ButtonWhite
                variant="outlined"
                startIcon={<IdeaIcon />}
                onClick={() => setOpenDialog(true)}
                text={t('ideas')}
              />
            </Stack>
          </Stack>
        </NanoPaper>

        {(webhookDoc?.value === 'true' || apiDoc?.value === 'true') && (
          <AccessRoleComponent resource="developer_doc" operation={['READ']}>
            <NanoPaper>
              <Stack
                direction={mobileFormat ? 'column' : 'row'}
                alignItems="center"
                justifyContent="space-between"
                spacing={1.5}
              >
                <Typography variant="h5">{t('doc_for_developers')}</Typography>
                <Stack direction="row" spacing={2}>
                  {apiDoc?.value === 'true' && (
                    <ButtonWhite
                      variant="outlined"
                      onClick={() =>
                        window.open(`//developers.nanolike.${window.location.host.split('.')[2]}`)
                      }
                      text={t('api_doc_btn')}
                    />
                  )}
                  {webhookDoc?.value === 'true' && (
                    <ButtonWhite
                      variant="outlined"
                      onClick={() => window.open('webhook-binconnect.html')}
                      text={t('webhook_doc_btn')}
                    />
                  )}
                </Stack>
              </Stack>
            </NanoPaper>
          </AccessRoleComponent>
        )}
        <NanoPaper>
          <Stack
            direction={mobileFormat ? 'column' : 'row'}
            alignItems="center"
            justifyContent="space-between"
            spacing={1.5}
          >
            <Typography variant="h5">{t('more_help')}</Typography>
            <ButtonWhite
              variant="outlined"
              startIcon={<SupportIcon />}
              onClick={() => {
                window.open(`https://binconnect.crisp.help/${supportedLanguage}`)
              }}
              text={t('help_center')}
            />
          </Stack>
        </NanoPaper>
        {openDialog && (
          <FeedbackModal
            isOpen={openDialog}
            onClose={() => setOpenDialog(false)}
            onSuccess={onSuccess}
          />
        )}

        {successPage && (
          <ConfirmationModal
            isOpen={successPage}
            onClose={() => setSuccessPage(false)}
            description="confirmation_feedback"
            buttonTitle="help_menu"
          />
        )}
      </Stack>
    </>
  )
}

HelpPage.propTypes = propTypes
HelpPage.defaultProps = defaultProps

export default HelpPage
