import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material'
import { debounce } from '@mui/material/utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../icons/PoiIconBold.svg'
import { ReactComponent as HashtagIcon } from '../icons/HashtagIcon.svg'
import { ReactComponent as Arrow } from '../icons/ArrowRightIconBold.svg'
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg'
import NanoMenuItem from './NanoMenuItem'
import { ContentIcon } from '../icons/index'
import { sendEvent } from '../utils/analyticsUtils'
import NanoDialog from '../../shared/components/NanoDialog'
import { client } from '../apiClient'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function SearchDevice({ isOpen, onClose }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()

  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)

  // Fonction pour récupérer les résultats
  const fetchResults = async (search = '', page = 1) => {
    const query = { search, page, page_size: 15 }
    if (search.length > 1) {
      const response = await client.GET('/v2/dashboard/', { params: { query } })
      return response.data.results.map((dashboard) => ({
        label: dashboard.device_name,
        others: { ...dashboard },
      }))
    }
    return []
  }

  // Fonction pour gérer les données et la pagination
  const fetchData = useMemo(
    () =>
      debounce((search, page) => {
        setLoading(true)
        fetchResults(search, page)
          .then((response) => {
            if (search) {
              sendEvent('search_device', { query: search })
            }
            setResults((prevResults) => (page === 1 ? response : [...prevResults, ...response]))
          })
          .finally(() => setLoading(false))
      }, 500),
    []
  )

  // Déclenche fetchData à chaque changement de `searchQuery` ou `page`
  useEffect(() => {
    fetchData(searchQuery, page)
  }, [searchQuery, page, fetchData])

  // Gestion du défilement
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    if (scrollHeight - scrollTop <= clientHeight + 50 && !loading) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  // Gestion de l'entrée utilisateur avec debounce
  const debouncedSearchChange = useMemo(
    () =>
      debounce((newQuery) => {
        setPage(1) // Réinitialise la pagination
        setResults([]) // Efface les résultats actuels
        setSearchQuery(newQuery) // Met à jour la recherche
      }, 300), // Délai avant l'exécution
    []
  )

  const handleSearchChange = (e) => {
    const newQuery = e.target.value
    debouncedSearchChange(newQuery) // Utilisation du debounce ici
  }

  // Désactive le défilement global lorsqu'une boîte de dialogue est ouverte
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <NanoDialog
      open={isOpen}
      onClose={onClose}
      disablePortal
      scroll="paper"
      PaperProps={{
        sx: {
          width: '50%',
          maxHeight: '75%',
        },
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start', // Positionne la boîte de dialogue en haut
        },
      }}
    >
      <TextField
        autoFocus
        fullWidth
        placeholder={t('search_bin_ph')}
        onChange={handleSearchChange} // Utilisation du gestionnaire debouncé
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
          style: { backgroundColor: 'white' },
        }}
      />
      <Box
        sx={{
          height: '100%',
          maxHeight: 'calc(75vh - 100px)', // Limite la hauteur pour s'adapter au conteneur de la boîte de dialogue
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {results.map((option) => (
          <NanoMenuItem
            key={option.others.device_reference}
            onClick={() => navigate(`/devices/${option.others.device_reference}`)}
          >
            <Grid ml={2} container spacing={0} direction="column">
              <Grid item container spacing={0} alignItems="center">
                <Grid item>
                  <Typography noWrap width={250} variant="h5">
                    {option.label}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <PoiIcon />
                </Grid>
                <Grid item xs={8}>
                  <Typography noWrap width={250} variant="body2">
                    {option.others?.group_poi?.group_poi_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <ContentIcon />
                </Grid>
                <Grid item xs={8}>
                  <Typography noWrap width={250} variant="body2">
                    {option?.others?.deviceContent ?? t('not_applicable')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <HashtagIcon />
                </Grid>
                <Grid item xs={8}>
                  <Typography noWrap variant="body2">
                    {option?.others?.device_reference}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <IconButton>
              <Arrow fill={theme.palette.secondary.main} stroke={theme.palette.secondary.main} />
            </IconButton>
          </NanoMenuItem>
        ))}
      </Box>
    </NanoDialog>
  )
}

SearchDevice.propTypes = propTypes
SearchDevice.defaultProps = defaultProps

export default SearchDevice
