import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { devicePropTypes } from '../model'
import DeviceTitle from '../../../shared/components/DeviceTitle'
import NanoPaper from '../../../shared/components/NanoPaper'
import { Button, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import { EditIcon, MediaIcon } from '../../../shared/icons'
import { useTranslation } from 'react-i18next'
import QuickActions from './QuickActions'
import MediaDialog from './MediaDialog'
import { client } from '../../../shared/apiClient'

const propTypes = {
  device: devicePropTypes.isRequired,
  loading: PropTypes.bool,
  handleEdit: PropTypes.func.isRequired,
  displayOrder: PropTypes.bool,
}

const defaultProps = { loading: false, displayOrder: false }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const DeviceFirstSection = ({ device, loading, handleEdit, displayOrder }) => {
  const { t } = useTranslation()
  const [displayMedia, setDisplayMedia] = React.useState(false)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const xlarge = !useMediaQuery(theme.breakpoints.up('xl'))
  const [media, setMedia] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const getMedia = async () => {
    if (media || dataLoading) return
    setDataLoading(true)
    try {
      const mediaResponse = await client.GET('/v2/devices/{id}/medias', {
        params: { path: { id: device.device_reference } },
      })
      setMedia(mediaResponse.data)
    } catch (error) {
      console.error('Error fetching media', error)
    } finally {
      setDataLoading(false)
    }
  }
  return (
    <Grid container justifyContent="space-between" spacing={2} alignItems="center">
      <Grid item xl={6} lg={5} md={6} sm={6}>
        <NanoPaper>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DeviceTitle {...device} deviceDetail />
            {!loading && (
              <Stack direction={!xlarge ? 'row' : 'column'} spacing={1}>
                <Button
                  startIcon={<MediaIcon />}
                  style={{ backgroundColor: 'white', color: 'black', border: '1px solid #EFEFF3' }}
                  onClick={() => {
                    getMedia()
                    setDisplayMedia(true)
                  }}
                  onMouseEnter={() => getMedia()}
                >
                  {!mobileFormat && t('media')}
                </Button>
                <Button
                  startIcon={<EditIcon />}
                  style={{ backgroundColor: 'white', color: 'black', border: '1px solid #EFEFF3' }}
                  onClick={handleEdit}
                >
                  {!mobileFormat && t('edit')}
                </Button>
              </Stack>
            )}
          </Stack>
        </NanoPaper>
      </Grid>
      <Grid item sm={6}>
        <QuickActions device={device} loading={loading} displayOrder={displayOrder} />
      </Grid>

      {displayMedia && (
        <MediaDialog
          media={media ?? []}
          isLoading={dataLoading}
          open={displayMedia}
          onClose={() => setDisplayMedia(false)}
        />
      )}
    </Grid>
  )
}

DeviceFirstSection.propTypes = propTypes
DeviceFirstSection.defaultProps = defaultProps

export default DeviceFirstSection
