import PropTypes from 'prop-types'
import { useSessionStore } from '../store'

const propTypes = {
  resource: PropTypes.string.isRequired,
  operation: PropTypes.arrayOf(PropTypes.string),
  // onlyGlobal: PropTypes.bool,
  fallbackComponent: PropTypes.node, // fallback with a component
  fallbackCallback: PropTypes.func, // fallback with a function
  children: PropTypes.node,
}

const defaultProps = {
  fallbackComponent: null,
  fallbackCallback: null,
  operation: ['READ'],
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const AccessRoleComponent = ({
  resource,
  operation,
  children,
  fallbackComponent,
  fallbackCallback,
}) => {
  const me = useSessionStore((state) => state.user)
  const hasPermission =
    me.workspace_role.authorisation[resource]?.includes('*') ||
    me.workspace_role.authorisation[resource]?.some((op) => operation.includes(op))
  if (hasPermission) {
    return children
  }

  if (fallbackComponent) {
    return fallbackComponent
  } else if (fallbackCallback) {
    return fallbackCallback()
  }
}
AccessRoleComponent.propTypes = propTypes
AccessRoleComponent.defaultProps = defaultProps

export default AccessRoleComponent
